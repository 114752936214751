button {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    width: 100px;
    height: 60px;
    color:white;
}

.buttonh1:hover {
    color:#41FF00;
}

nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 2rem;
    border-bottom: 3px solid #fff;
}

nav ul {
    display: flex;
    list-style: none;
}

nav li {
    padding-left: 1rem;
}

#red{
    color: red;
}