* {
    padding: 0;
    margin: 0;
    background-color: black;
    font-size: 24px;
}

body {
    color: rgb(0, 255, 0);
}

.welcome {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.welcome button {
    background-color: white;
    color: black;
    text-align: center;
    width: 100%;
    margin-top: 20px;
}

.level1 {
    background-color: 'white;';
    color: 'black';
}

input {
    background-color: white;
    color: black;
}

#hof {
    color: white;
}

#hof span {
    color: red;
}

.contact a:hover {
    color: rgb(0, 255, 0);
}

.contact a:visited {
    color: rgb(0, 255, 0);
}

.contact a:active {
    color: rgb(0, 255, 0);
}

.projects{
    display: flex;
    justify-content: space-between;
    width: 70%;
}

.projects img{
    width:  400px;
    height: 350px;
    padding: 20px;
}